<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-for="card in cards"
        :key="card.title"
        cols="12"
        sm="6"
        lg="4"
        style="position: relative"
      >
        <base-material-stats-card
          :color="card.color"
          :icon="card.icon"
          :title="card.title"
          :value="card.value"
          :sub-icon="card.subIcon"
          :sub-text="card.subText"
          elevation="12"
        />
        <v-overlay
          :value="card.loading"
          absolute
        >
          <v-progress-circular
            v-show="card.loading"
            size="100"
            width="7"
            indeterminate
            color="primary"
          />
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  const moment = require('moment')

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        cards: {
          plans: {
            color: 'primary',
            icon: 'mdi-calendar-month',
            title: 'Planes Activos',
            value: '0',
            loading: true,
          },
          users: {
            color: 'secondary',
            icon: 'mdi-account',
            title: 'Usuarios Registrados',
            value: '0',
            loading: true,
          },
        },
      }
    },
    mounted () {
      this.getUsers()
      this.getPlans()
    },
    methods: {
      async getUsers () {
        const users = await this.$api.User.list()
        this.cards.users.value = users.length.toString()
        this.cards.users.loading = false
      },
      async getPlans () {
        const now = moment(Date.now()).format('YYYY-MM-DD')
        const params = {
          end_gte: now,
          status: true,
        }
        const plans = await this.$api.UserPlan.list(params)
        this.cards.plans.value = plans.length.toString()
        this.cards.plans.loading = false
      },
    },
  }
</script>
